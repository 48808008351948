@import "@/styles/index.scss";

.SubCategoryList {
    flex: 1;
    padding: 0.5rem 0;

    .tiles {}
}

.levelOne, .levelTwo {
    display: flex;
    align-items: center;

    .name {
        max-width: calc(100% - 24px);
        //@include ellipsisMultiLines(1);
    }
}

.levelOne {
    margin-bottom: 8px;
    font-weight: 700;
    @include setProperty(color, --text-color-bold, true);
    @include hovered(true);
    font-size: 14px;
    line-height: normal;
}

.levelTwo {
    padding: 2px 0;
    @include setProperty(color, --text-color-bold, true);
    font-size: 13px;
    line-height: 15px;
    @include hovered(true);
    min-height: 24px;

    .name {
        //@include ellipsisMultiLines(1);
    }
}

.noNested {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 2rem;
    padding: 4rem 0;
}
