@import "@/styles/index.scss";

.CardSale {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mediaParameter(width, 341px, 356px, 354px, 327px);
    @include mediaParameter(height, 216px, 216px, 216px, 200px);
    border-radius: 8px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        cursor: pointer;
        @include setProperty(opacity, --opacity-value);
    }
}
