@import "@/styles/index.scss";

.SlideProducts {
    text-align: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include mediaParameter(margin-top, 22.5px, 22.5px, 22.5px, 20px);
        @include mediaParameter(margin-bottom, 18.5px, 18.5px, 18.5px, 16px);

        .title {
            text-align: center;
            font-family: var(--font-inter);
            white-space: nowrap;
            margin: 0;
            @include mediaParameter(font-size, 26px, 26px, 26px, 21px);
            @include setProperty(color, --color-black);
        }
    }

    .mobileList {
        display: flex;
        flex-wrap: wrap;
        gap: var(--layout-column-gap);
        /*
        // dividing lines
        @include mobileParameter(gap, 1px);
        @include mobileParameter(background-color, #E6EAED);
        */
    }

    .slideItem {
        @include homeProductWrapItem();
    }
}
