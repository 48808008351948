.slick-slider {
    height: 100%;
}

.slick-list {
    height: 100%;
}

.slick-track  {
    height: 100%;
}

.slick-track > div, .slick-track > div > div {
    height: 100%;
}

.slick-dots {
    bottom: 14px;
}

.slick-dots li {
    width: 10px;
    height: 10px;
}

.slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0;
}

.slick-dots li button:before {
    line-height: 10px;
    position: absolute;
    width: 10px;
    height: 10px;
    opacity: 1;
    color: #F0F2F5;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    /* primary */
    color: #0D7D8B;
}

