@import "@/styles/index.scss";

.TopCategoriesClientWrap {
    position: relative;
}

.TopCategoriesClient,
.TopCategoriesLayout,
.TopCategories {
    .item {
        display: flex;
        align-items: center;
        padding: 4px 4px;
        font-weight: 400;
        transition: font-weight 0.3s, color 0.3s;
        @include hovered(true);

        &.active {
            background: #F0F2F5;

            .name {
                @include setProperty(color, --color-black, true);
                text-decoration: underline;
            }
        }

        .icon {
            height: 24px;
            width: 24px;
            min-width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 2px;
        }

        .name {
            color: #191C1F;
            @include ellipsisMultiLines(1);
            @include labelFontSize;
            font-size: 14px;
            line-height: 15px;
        }
    }

    a {
        @include hovered(true);
        &:hover {
            background-color: #F0F2F5 !important;
            .name {
                text-decoration: underline;
                @include setProperty(color, --color-black, true);
            }
        }
    }
}

.TopCategoriesLayout {
    position: absolute;
    left: -8px;
    right: -8px;

    a > img {
        opacity: 0;
        display: none;
    }
}

.TopCategoriesClient {
    left: 0px !important;
    width: 100%;
}
