@import "@/styles/index.scss";

.Banner {
    height: 100%;
    position: relative;

    .img {
        width: 100%;
        height: 100%;
        pointer-events: all;
    }

    .carousel {
        height: 100%;
        @include mediaParameterSm(border-radius, 0);
        @include mobileParameter(border-radius, unset);
    }

    .bannerItem {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        border-radius: 8px;
        @include mediaParameterSm(border-radius, 0);
        @include mobileParameter(border-radius, 8px);

        .info {
            font-family: var(--font-inter);
            font-weight: 700;
            font-size: 2.5rem;
            color: #FFFFFF;
            text-align: left;

            .text {
                margin-bottom: 1rem;
            }

            .title {
                font-size: 1.3125rem;
                letter-spacing: 0.05px;
            }
        }

        &.mock {
            filter: blur(10px);
        }
    }
}
