@import "@/styles/index.scss";

.wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border: 2px solid #f3f3f3;
  @include setProperty(border-top-color, --color-primary);
  //border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
