@import "@/styles/index.scss";

.Breadcrumbs {
    width: 100%;;
    //display: flex;
    align-items: center;
    flex-wrap: wrap;

    overflow-x: auto;
    // padding: 8px 0;

    list-style: none;
    padding-inline-start: 0;
    margin: 0;

    @include setProperty(font-size, --font-size-sm);
    line-height: 16px;
    letter-spacing: 0.005em;

    display: block;

    @media screen and (max-width: $mobile) {
        @include hiddenScroll();
    }

    li {
        display: inline;
        //display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        white-space: normal;
        word-break: break-word;
    }

    .link {
        text-decoration: none;
        @include hovered(true);
        @include highlight();
        //white-space: nowrap;
        word-break: break-word;
        display: inline;
    }

    .separator {
        display: inline;
        margin: 0 5px;
        color: #828B94;
    }
}
