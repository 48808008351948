@import "@/styles/index.scss";

.Slide {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;

    .list {
        display: flex;
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        position: absolute;

        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: 0px;
        scrollbar-width: none;
        /* Firefox 64 */

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        white-space: nowrap;
        scroll-behavior: smooth;

        .wrapItem {
            display: inline-block;
            box-sizing: border-box;
            opacity: 1;
            border: 1px solid;
            border-color: #e6e6e6;
            // for Iphone & macOS
            vertical-align: text-top;

            transition: border-color 0.3s ease-in-out, opacity 0.3s;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                @include setProperty(border-color, --color-primary);
            }

            &.highlightOnHover {
                &:hover {
                    @include setProperty(opacity, --opacity-value);
                }
            }

            &:hover {
                cursor: pointer;
            }

            &>* {
                box-sizing: border-box;
            }
        }
    }

    .arrowLeft,
    .arrowRight {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        top: calc(50% - 24px);
        transform: scale(0);
        color: #828B94;
        background-color: white;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

        transition: transform 0.3s ease-out;

        cursor: pointer;

        &::after {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: rgb(179, 179, 179);
            color: rgb(255, 255, 255);
            font-size: 1.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            transition: background-color 0.2s;
        }

        &:hover {
            &::after {
                @include setProperty(background-color, --color-primary);
            }
        }

        &.show {
            transform: scale(1);
        }

        img:hover {
            filter: brightness(0) !important;
        }
    }

    .arrowLeft {
        left: 8px;

        &.offsetOutside {
            left: -70px;
        }
    }

    .arrowRight {
        right: 8px;

        &.offsetOutside {
            right: -70px;
        }
    }

    &.autoMargin {
        .arrowLeft {
            @include mediaParameterMd(left, 18px);
        }

        .arrowRight {
            @include mediaParameterMd(right, 18px);
        }
    }
}
