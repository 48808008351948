@import "@/styles/index.scss";

.CustomCarousel {
    width: 100%;
    position: absolute;
    overflow: hidden;

    .arrow {
        position: absolute;
        z-index: 2;
        display: flex;
        @media screen and (max-width: ($md - 1)) {
            display: none;;
        }
        justify-content: center;
        top: calc(50% - 24px);
        margin-left: 4px;
        width: 65px;
        background: none;
        cursor: pointer;

        &:hover {
            & img {
                filter: brightness(0) !important;
            }
        }

        &>* {
            color: #828B94;
            background: white;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

            &> img {
                width: 24px;
                height: 24px;
            }
        }

        &.hide {
            transform: scale(0);
            transition: transform 0.3s ease-out;
        }

        &.show {
            transform: scale(1) !important;
        }

        &.prev {}

        &.next {
            right: 0;
            margin-right: 4px;
        }
    }
}
