@import "@/styles/index.scss";

.MoreProductsList {
    margin: 20px 0 64px 0;
    @include mobileParameter(margin-bottom, 40px);

    .title {
        font-family: var(--font-inter);
        white-space: nowrap;
        @include mediaParameter(margin-bottom, 20px, 20px, 20px, 16px);
        @include mediaParameter(font-size, 26px, 26px, 26px, 21px);
        @include setProperty(color, --color-black);
    }

    .list {
        @include productsListContainer();
    }

    .wrapItem {
        @include homeProductWrapItem();

        &.dirty {
            @include draftItem();
        }
    }
}
