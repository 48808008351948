@import "@/styles/index.scss";

.homePage {
    display: flex;
    min-height: 150vh;

    .content {
        width: 100%;
        @include mediaParameter(padding-top, 20px, 20px, 0, 0);

        .banner {
            @include mediaParameter(--cols, 10, 6, 6, 4);
            --width: calc(var(--layout-column-width) * var(--cols) + var(--layout-column-gap) * (var(--cols) - 1));

            // 16px - scroll width
            @include mediaParameterSm(--width, calc(100vw - 16px));
            @include mobileParameter(--width, 100vw);
            // @include mediaParameterSm(--width, calc(100vw - 40px - var(--layout-scroll-width)));
            @include mobileParameter(--width, calc(100vw - 24px));

            width: var(--width);

            @include mediaParameterSm(transform, translateX(calc(-1 * var(--layout-column-gap))));
            // @include mediaParameterSm(transform, translateX(0px));
            @include mobileParameter(transform, translateX(0px));
            // ratio = 1063px / 300px = 3.543333333
            height: calc(var(--width) / 3.543333333);

            // ratio = 375px / 200px = 1.875
            @include mobileParameter(height, calc(var(--width) / 1.875));

            // @include mobileParameter(height, calc(var(--width) / 1.171875));
            // OR
            // @media screen and (max-width: $xs) {
            //     // ratio = 375px / 320px = 1.171875
            //     height: calc(var(--width) / 1.171875);
            // }

        }
    }

    --card-footer-card: 95px;

    .slideContent {
        min-height: calc(var(--width_x2) + var(--card-footer-card));
        @include mobileParameter(min-height, calc(var(--width_x2) * 2 + var(--card-footer-card) * 2));

        &>* {
            // TODO fixes slider init
            min-height: calc(var(--width_x2) + var(--card-footer-card));
        }
    }

    .saleProducts {
        @include mediaParameter(margin-top, 20px, 20px, 20px, 16px);
    }
}
