@import "@/styles/index.scss";

.leftSide {
    @include width_x2();
    position: relative;
    padding-top: 20px;
    padding-bottom: 64px;
    margin-right: var(--layout-column-gap);

    @media screen and (max-width: ($md - 1)) {
        display: none;
    }

    &.stickToTop {
        position: sticky;
        top: $stickyMenuHeight;
        align-self: flex-start;
    }

    &.stickToTopRelative {
        position: relative;
        top: 0;
        align-self: flex-start;
    }

    &.stickToBottom {
        position: sticky;
        bottom: 0;
        align-self: flex-end;
    }

    &.active {
        z-index: 10000;
    }

    .background {
        --p: 20px;
        position: absolute;
        height: calc(100% + 2 * var(--p));
        width: calc(100% + var(--p));
        left: calc(-1 * var(--p));
        top: calc(-1 * var(--p));
        background: white;
        padding: var(--p) 0 var(--p) var(--p);
        border-radius: 8px 0 0 8px;

        pointer-events: none;
        opacity: 0;

        &.show {
            pointer-events: all;
            opacity: 1;
        }
    }

    .backdoor {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include setProperty(background-color, --backdoor-color);
        z-index: 9999;
        pointer-events: none;
        opacity: 0;

        transition: none;

        &.show {
            pointer-events: all;
            opacity: 1;
            transition: opacity 0.3s;

        }
    }

    .wrapActive {
        position: relative;
        z-index: 9999;
        width: calc(var(--width_x2) + 16px);
        opacity: 1;

        [data-id="top-categories"] {
            width: calc(100% - 0px);

            a > img {
                display: inline-block;
                opacity: 1 !important;
            }
        }
    }

    a {
        @include hovered(true);

        &:hover {
            @include setProperty(color, --color-hovered, true);
        }
    }
}

.topCategoryActive {
    background-color: #F0F2F5 !important;
    //background-color: rgba(0, 122, 255, 0.05) !important;
    font-weight: 700;
    img:first-child {
        //filter: brightness(0) saturate(100%) invert(39%) sepia(50%) saturate(6787%) hue-rotate(200deg) brightness(103%) contrast(108%) !important;
    }
    div {
        @include setProperty(color, --color-black, true);
        text-decoration: underline;
    }
}

.sub {
    flex: 1;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: calc(100% + 16px);
    z-index: 9999;
    min-width: calc(var(--layout-column-width) * (var(--layout-columns) - 2) + var(--layout-column-gap) * (var(--layout-columns) - 1));

    background: white;
    padding: 32px 84px;
    border-radius: 0 8px 8px 0;

    &>div {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4;
        @include mediaParameter(-webkit-columns, 4, 4, 3, 3);
        @include mediaParameter(-moz-columns, 4, 4, 3, 3);
        @include mediaParameter(columns, 4, 4, 3, 3);
        -webkit-column-gap: 24px;
        -moz-column-gap: 24px;
        column-gap: 24px;

        &>div {
            padding-bottom: 32px;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
        }

        &.columns2 {
            columns: 2;
        }
    }

    a:hover {
        @include hovered();
        @include setProperty(color, --color-black, true);
    }
}
