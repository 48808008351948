@import "@/styles/index.scss";

.SlideSales {
    position: relative;
    margin: 20px 0 20px 0;
    @include mediaParameter(margin, 20px 0 20px 0, 20px 0 20px 0, 220px 0 20px 0px, 16px 0 16px 0);

    .header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaParameter(margin-top, 21px, 21px, 21px, 20px);
        @include mediaParameter(margin-bottom, 18px, 18px, 18px, 16px);

        .title {
            text-align: left;
            font-family: var(--font-inter);
            white-space: nowrap;
            margin: 0;

            @include mediaParameter(font-size, 26px, 26px, 26px, 21px);
            @include mediaParameter(line-height, 32px, 32px, 32px, 26px);
        }
    }

    // .list {
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin: 0 -15px;
    //     margin-bottom: 2.5rem;
    //     margin-top: 2rem;
    // }

    .wrap,
    .slide {
        @include mediaParameter(height, 216px, 216px, 216px, 200px);

        :global(.slick-list) {
            margin: 0 -10px;
        }
        :global(.slick-slide>div) {
            padding: 0 10px;
        }
    }

    .slideItem {}

    .btnMore {
        @include mediaParameter(margin-top, 24px, 24px, 24px, 16px);
    }
}
